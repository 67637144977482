import React from "react"
import { graphql } from "gatsby"
import List from "../components/list"

const BlogTagTemplate = ({ data, pageContext, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <List
      posts={posts}
      location={location}
      siteTitle={siteTitle}
      pageCount={1}
      currentPage={1}
      pageTitle={pageContext.tag}
    />
  )
}

export default BlogTagTemplate
export const blogTagQuery = graphql`
  query blogTagQuery($tag: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
            tags
          }
        }
      }
    }
  }
`
